import type { TFunction } from "i18next";

const renderDeadline = (t: TFunction, val: number): React.ReactElement => {
  function convertHoursToTime(hours: number): any {
    const sign = hours < 0 ? "-" : "+";
    const absoluteHours = Math.abs(hours);

    const totalMinutes = Math.round(absoluteHours * 60);
    const days = Math.floor(totalMinutes / (60 * 24));
    const remainingMinutesAfterDays = totalMinutes % (60 * 24);
    const hoursPart = Math.floor(remainingMinutesAfterDays / 60);
    const minutesPart = remainingMinutesAfterDays % 60;

    return {
      sign,
      days,
      hours: hoursPart,
      minutes: minutesPart,
    };
  }

  return (
    <span
      className="rounded-md py-1 px-2 text-nowrap"
      style={{
        background:
          convertHoursToTime(val)?.sign === "+" ? "#6FCF9733" : "#EB575733",
        color: convertHoursToTime(val)?.sign === "+" ? "#6FCF97" : "#EB5757",
      }}
    >
      {convertHoursToTime(val)?.days} {t("days")}{" "}
      {convertHoursToTime(val)?.hours} {t("hours")}{" "}
      {convertHoursToTime(val)?.minutes} {t("minutes")}
    </span>
  );
};

export default renderDeadline;
