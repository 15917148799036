import arrowLeftIcon from "@/assets/arrow-left.svg";
import DefaultAvatar from "@/assets/default-avatar.jpg";
import phoneIcon from "@/assets/phone.svg";
import calendarIcon from "@/assets/date-calendar.svg";
import userIcon from "@/assets/user.svg";
import passportIcon from "@/assets/passport.svg";
import signatureIcon from "@/assets/signature.svg";
import CustomModal from "@/components/modal";
import TabularData from "@/components/tabular-data";
import { Info } from "@/features/acts-list";
import formatDate from "@/helpers/format-date";
import {
  Alert,
  Button,
  ConfigProvider,
  Divider,
  Flex,
  Image,
  Input,
  Layout,
  Select,
  Spin,
  Typography,
} from "antd";
import { Map, Placemark, YMaps } from "react-yandex-maps";
import "swiper/css";
import "swiper/css/navigation";
import CancelIcon from "../../components/cancel-icon";
import TickIcon from "../../components/tick-icon";
import useDetailsState from "./state";
import PinnedRows from "./PinnedRows";

const { Header } = Layout;

export default function Details(): React.ReactElement {
  const {
    Title,
    t,
    contextHolder,
    colorBgContainer,
    goBack,
    reason,
    setReason,
    note,
    setNote,
    reasons,
    onNullify,
    onReceive,
    onReturn,
    formDetails,
    isFormDetailsLoading,
    setIsReturnModalOpen,
    setIsCancelModalOpen,
    isReturnModalOpen,
    onReturnModalCancel,
    isCancelModalOpen,
    onCancelModalCancel,
    isOpenMapModal,
    setIsOpenMapModal,
    onCloseMapModal,
    openModalImage,
    setOpenModalImage,
  } = useDetailsState();

  const nullified = false;
  console.log("formDetails", formDetails);

  return (
    <>
      {contextHolder}

      <Header
        style={{ background: colorBgContainer }}
        className="px-8 pt-2 flex items-center gap-4"
      >
        <Flex className="justify-between w-full">
          <Flex className="items-center" gap={12}>
            <button type="button" onClick={goBack}>
              <img src={arrowLeftIcon} alt="back" width={24} height={24} />
            </button>
            <h1 className="font-semibold text-2xl">{t("form-details")}</h1>
          </Flex>

          <Alert
            message={formDetails?.is_juridic ? t("jurface") : t("phyface")}
            type="success"
          />
        </Flex>
      </Header>

      <div className="bg-white p-8">
        <Spin spinning={isFormDetailsLoading}>
          <TabularData
            rows={[
              {
                name: t("offense-type"),
                value: formDetails?.violation_type?.name ?? "-",
              },
              {
                name: t("Korxona nomi"),
                value:
                  formDetails?.employee?.organization?.name ??
                  "O`ZENERGOINSPEKSIYA",
              },
              {
                name: t("act-type"),
                value: t(formDetails?.act_type) ?? "-",
              },
              {
                name: t("series-number"),
                value: `${formDetails?.act_series ?? ""} ${
                  formDetails?.act_number ?? ""
                }`.trim(),
              },
              {
                name: t("date-act"),
                value: formatDate(formDetails?.act_date ?? "-"),
              },
              {
                name: t("region"),
                value: formDetails?.region?.name ?? "-",
              },
              {
                name: t("district"),
                value: formDetails?.district?.name ?? "-",
              },
              {
                name: t("address-act"),
                value: formDetails?.address ?? "-",
              },
              {
                name: t("story"),
                value: formDetails?.fabula ?? "-",
              },
              {
                name: t("raz-letter"),
                value: formDetails?.explanatory_letter ?? "-",
              },
            ]}
          />

          {formDetails?.files?.length > 0 && (
            <>
              <Typography.Text className="text-[#8498B4] font-normal text-[15px]">
                {t("pinned-files")}
              </Typography.Text>

              {formDetails?.files?.length > 0 && (
                <TabularData
                  rows={formDetails?.files?.map((item: any) => ({
                    name: t("file"),
                    value: (
                      <PinnedRows
                        key={item.id}
                        item={item}
                        t={t}
                        formDetails={formDetails}
                      />
                    ),
                  }))}
                />
              )}
            </>
          )}

          <Typography.Text className="text-[#8498B4] font-normal text-[15px]">
            {t("info-inspector")}
          </Typography.Text>
          <Flex
            vertical
            className="border border-1 border-solid border-[#8498B44D] rounded-[8px] p-[20px] bg-[#FAFBFC] my-4"
          >
            <Flex className="items-center justify-between">
              <Flex className="items-center" gap={20}>
                <Flex className="border border-1 border-solid border-[#8498B44D] overflow-hidden rounded-[8px] h-[48px] w-[48px] items-center justify-center bg-[#FFFFFF]">
                  <Image preview={false} src={DefaultAvatar} alt="phone" />
                </Flex>

                <Flex vertical gap={6}>
                  <Typography.Text className="text-[#081C15] font-medium text-base">
                    {`${formDetails?.employee?.lastname ?? ""} ${
                      formDetails?.employee?.firstname ?? ""
                    } ${formDetails?.employee?.middlename ?? ""}`.trim()}
                  </Typography.Text>
                  <Typography.Text className="text-[#40916C] font-normal text-sm">
                    {t("inspectors")}
                  </Typography.Text>
                </Flex>
              </Flex>

              <Flex className="items-center" gap={16}>
                <Button className="p-[8px_12px] flex gap-6 h-auto rounded-[8px] border border-1 border-solid border-[#D7DDE6]">
                  <Flex>
                    <Image src={phoneIcon} alt="phone" preview />
                  </Flex>

                  <Flex vertical gap={4} className="items-start">
                    <Typography.Text className="text-[#8498B4] font-normal text-sm">
                      {t("phone")}
                    </Typography.Text>
                    <Typography.Text className="text-[#081C15] font-normal text-sm">
                      {formDetails?.employee?.phone_number ?? "-"}
                    </Typography.Text>
                  </Flex>
                </Button>
              </Flex>
            </Flex>

            <Divider className="my-[16px]" />

            <Flex gap={36}>
              <Flex vertical gap={8}>
                <Typography.Text className="text-[#8498B4] font-normal text-sm">
                  {t("pinfl")}
                </Typography.Text>
                <Typography.Text className="text-[#081C15] font-normal text-sm">
                  {formDetails?.employee?.pinfl ?? "-"}
                </Typography.Text>
              </Flex>

              <Flex vertical gap={8}>
                <Typography.Text className="text-[#8498B4] font-normal text-sm">
                  {t("series-udos")}
                </Typography.Text>
                <Typography.Text className="text-[#081C15] font-normal text-sm">
                  {formDetails?.employee?.certificate ?? "-"}
                </Typography.Text>
              </Flex>

              {formDetails?.employee?.signature && (
                <Flex vertical gap={8}>
                  <Typography.Text className="text-[#8498B4] font-normal text-sm">
                    {t("Imzo")}
                  </Typography.Text>
                  <Button
                    type="link"
                    onClick={() => {
                      setOpenModalImage(formDetails?.employee?.signature ?? "");
                    }}
                    className="!underline font-normal text-sm p-0"
                  >
                    {t("see")}
                  </Button>
                </Flex>
              )}
            </Flex>
          </Flex>
          <Typography.Text className="text-[#8498B4] font-normal text-[15px]">
            {t("data-narushitel")}
          </Typography.Text>
          <Flex
            vertical
            className="border border-1 border-solid border-[#8498B44D] rounded-[8px] p-[20px] bg-[#FAFBFC] my-4"
          >
            <Flex className="items-center justify-between">
              <Flex className="items-center" gap={20}>
                <Flex className="border border-1 border-solid border-[#8498B44D] overflow-hidden rounded-[8px] h-[48px] w-[48px] items-center justify-center bg-[#FFFFFF]">
                  <Image preview={false} src={DefaultAvatar} alt="phone" />
                </Flex>

                <Flex vertical gap={6}>
                  <Typography.Text className="text-[#081C15] font-medium text-base">
                    {`${formDetails?.violation_person?.lastname ?? ""} ${
                      formDetails?.violation_person?.firstname ?? ""
                    } ${
                      formDetails?.violation_person?.middlename ?? ""
                    }`.trim()}
                  </Typography.Text>
                  <Typography.Text className="text-[#EB5757] font-normal text-sm">
                    {t("narushitel")}
                  </Typography.Text>
                </Flex>
              </Flex>

              <Flex className="items-center" gap={16}>
                <Button className="p-[8px_12px] flex gap-6 h-auto rounded-[8px] border border-1 border-solid border-[#D7DDE6]">
                  <Flex>
                    <Image src={calendarIcon} alt="phone" preview />
                  </Flex>

                  <Flex vertical gap={4} className="items-start">
                    <Typography.Text className="text-[#8498B4] font-normal text-sm">
                      {t("dob")}
                    </Typography.Text>
                    <Typography.Text className="text-[#081C15] font-normal text-sm">
                      {formatDate(
                        formDetails?.violation_person?.birth_date ?? "",
                      )}
                    </Typography.Text>
                  </Flex>
                </Button>

                <Button className="p-[8px_12px] flex gap-6 h-auto rounded-[8px] border border-1 border-solid border-[#D7DDE6]">
                  <Flex>
                    <Image src={passportIcon} alt="phone" preview />
                  </Flex>

                  <Flex vertical gap={4} className="items-start">
                    <Typography.Text className="text-[#8498B4] font-normal text-sm">
                      {t("passport")}
                    </Typography.Text>
                    <Typography.Text className="text-[#081C15] font-normal text-sm">
                      {`${
                        formDetails?.violation_person?.document_series ?? ""
                      } ${
                        formDetails?.violation_person?.document_number ?? ""
                      }`.trim()}
                    </Typography.Text>
                  </Flex>
                </Button>

                <Button className="p-[8px_12px] flex gap-6 h-auto rounded-[8px] border border-1 border-solid border-[#D7DDE6]">
                  <Flex>
                    <Image src={phoneIcon} alt="phone" preview />
                  </Flex>

                  <Flex vertical gap={4} className="items-start">
                    <Typography.Text className="text-[#8498B4] font-normal text-sm">
                      {t("telefon")}
                    </Typography.Text>
                    <Typography.Text className="text-[#081C15] font-normal text-sm">
                      {formDetails?.violation_person?.phone ?? "-"}
                    </Typography.Text>
                  </Flex>
                </Button>
              </Flex>
            </Flex>

            <Divider className="my-[16px]" />

            <Flex gap={36}>
              <Flex vertical gap={8}>
                <Typography.Text className="text-[#8498B4] font-normal text-sm">
                  {t("birthplace")}
                </Typography.Text>
                <Typography.Text className="text-[#081C15] font-normal text-sm">
                  {formDetails?.violation_person?.place_of_birth ?? "-"}
                </Typography.Text>
              </Flex>

              <Flex vertical gap={8}>
                <Typography.Text className="text-[#8498B4] font-normal text-sm">
                  {t("district")}
                </Typography.Text>
                <Typography.Text className="text-[#081C15] font-normal text-sm">
                  {formDetails?.violation_person?.district ?? "-"}
                </Typography.Text>
              </Flex>

              <Flex vertical gap={8}>
                <Typography.Text className="text-[#8498B4] font-normal text-sm">
                  {t("residence-address")}
                </Typography.Text>
                <Typography.Text className="text-[#081C15] font-normal text-sm">
                  {formDetails?.violation_person?.address ?? "-"}
                </Typography.Text>
              </Flex>

              <Flex vertical gap={8}>
                <Typography.Text className="text-[#8498B4] font-normal text-sm">
                  {t("nationality")}
                </Typography.Text>
                <Typography.Text className="text-[#081C15] font-normal text-sm">
                  {formDetails?.violation_person?.nationality ?? "-"}
                </Typography.Text>
              </Flex>

              <Flex vertical gap={8}>
                <Typography.Text className="text-[#8498B4] font-normal text-sm">
                  {t("citizenship")}
                </Typography.Text>
                <Typography.Text className="text-[#081C15] font-normal text-sm">
                  {formDetails?.violation_person?.citizenship ?? "-"}
                </Typography.Text>
              </Flex>

              <Flex vertical gap={8}>
                <Typography.Text className="text-[#8498B4] font-normal text-sm">
                  {t("pinfl")}
                </Typography.Text>
                <Typography.Text className="text-[#081C15] font-normal text-sm">
                  {formDetails?.violation_person?.pinfl ?? "-"}
                </Typography.Text>
              </Flex>
            </Flex>
          </Flex>
          <Typography.Text className="text-[#8498B4] font-normal text-[15px]">
            {t("data-pred-nar")}
          </Typography.Text>
          <Flex
            vertical
            className="border border-1 border-solid border-[#8498B44D] rounded-[8px] p-[20px] bg-[#FAFBFC] my-4"
          >
            <Flex className="items-center justify-between">
              <Flex className="items-center" gap={20}>
                <Flex className="border border-1 border-solid border-[#8498B44D] overflow-hidden rounded-[8px] h-[48px] w-[48px] items-center justify-center bg-[#FFFFFF]">
                  <Image preview={false} src={DefaultAvatar} alt="phone" />
                </Flex>

                <Flex vertical gap={6}>
                  <Typography.Text className="text-[#081C15] font-medium text-base">
                    {formDetails?.violation_organization?.name ?? "-"}
                  </Typography.Text>
                  <Typography.Text className="text-[#EB5757] font-normal text-sm">
                    {t("pred")}
                  </Typography.Text>
                </Flex>
              </Flex>

              <Flex className="items-center" gap={16}>
                <Button className="p-[8px_12px] flex gap-6 h-auto rounded-[8px] border border-1 border-solid border-[#D7DDE6]">
                  <Flex>
                    <Image src={userIcon} alt="phone" preview={false} />
                  </Flex>

                  <Flex vertical gap={4} className="items-start">
                    <Typography.Text className="text-[#8498B4] font-normal text-sm">
                      {t("director's-fullname")}
                    </Typography.Text>
                    <Typography.Text className="text-[#081C15] font-normal text-sm">
                      {formDetails?.violation_organization?.director_fio ?? "-"}
                    </Typography.Text>
                  </Flex>
                </Button>

                <Button className="p-[8px_12px] flex gap-6 h-auto rounded-[8px] border border-1 border-solid border-[#D7DDE6]">
                  <Flex>
                    <Image src={phoneIcon} alt="phone" preview={false} />
                  </Flex>

                  <Flex vertical gap={4} className="items-start">
                    <Typography.Text className="text-[#8498B4] font-normal text-sm">
                      {t("telefon")}
                    </Typography.Text>
                    <Typography.Text className="text-[#081C15] font-normal text-sm">
                      {formDetails?.violation_organization?.phone ?? "-"}
                    </Typography.Text>
                  </Flex>
                </Button>
              </Flex>
            </Flex>

            <Divider className="my-[16px]" />

            <Flex gap={36}>
              <Flex vertical gap={8}>
                <Typography.Text className="text-[#8498B4] font-normal text-sm">
                  {t("number-contract")}
                </Typography.Text>
                <Typography.Text className="text-[#081C15] font-normal text-sm">
                  {formDetails?.violation_organization?.contract_number ?? "-"}
                </Typography.Text>
              </Flex>

              <Flex vertical gap={8}>
                <Typography.Text className="text-[#8498B4] font-normal text-sm">
                  {t("STIR")}
                </Typography.Text>
                <Typography.Text className="text-[#081C15] font-normal text-sm">
                  {formDetails?.violation_organization?.stir ?? "-"}
                </Typography.Text>
              </Flex>

              <Flex vertical gap={8}>
                <Typography.Text className="text-[#8498B4] font-normal text-sm">
                  {t("type-activity")}
                </Typography.Text>
                <Typography.Text className="text-[#081C15] font-normal text-sm">
                  {formDetails?.violation_organization?.type_of_activity ?? "-"}
                </Typography.Text>
              </Flex>

              <Flex vertical gap={8}>
                <Typography.Text className="text-[#8498B4] font-normal text-sm">
                  {t("legal-address")}
                </Typography.Text>
                <Typography.Text className="text-[#081C15] font-normal text-sm">
                  {formDetails?.violation_organization?.address ?? "-"}
                </Typography.Text>
              </Flex>

              <Flex vertical gap={8}>
                <Typography.Text className="text-[#8498B4] font-normal text-sm">
                  {t("on-map")}
                </Typography.Text>
                <Typography.Link
                  onClick={() => {
                    setIsOpenMapModal(true);
                  }}
                  className="text-[#081C15] font-normal text-sm"
                >
                  {t("view-on-map")}
                </Typography.Link>
              </Flex>
            </Flex>
          </Flex>
          {/* <Typography.Text className="text-[#8498B4] font-normal text-[15px]">
            {t("prilavok")}
          </Typography.Text>
          {formDetails?.violation_type?.key === "het" && (
            <TabularData
              rows={[
                {
                  name: t("model-prilavok"),
                  value: formDetails?.counter?.counter_type ?? "-",
                },
                {
                  name: t("factory-number"),
                  value: formDetails?.counter?.factory_number ?? "-",
                },
                {
                  name: t("indicator"),
                  value: formDetails?.counter?.indicator ?? "-",
                },
                {
                  name: t("transformator"),
                  value: formDetails?.counter?.electrical_transformer ?? "-",
                },
                {
                  name: t("preb-nap"),
                  value: formDetails?.counter?.tension_transformer ?? "-",
                },
                {
                  name: t("active-ind"),
                  value: formDetails?.counter?.active_indicator ?? "-",
                },
                {
                  name: t("no-active-ind"),
                  value: formDetails?.counter?.no_active_indicator ?? "-",
                },
                {
                  name: t("nomer-marok"),
                  value: formDetails?.counter?.stamp_number ?? "-",
                },
              ]}
            />
          )}
          {formDetails?.violation_type?.key === "hgt" && (
            <TabularData
              rows={[
                {
                  name: t("gas_counter"),
                  value: formDetails?.counter?.counter_type ?? "-",
                },
                {
                  name: t("factory_number"),
                  value: formDetails?.counter?.factory_number ?? "-",
                },
                {
                  name: t("indicator"),
                  value: formDetails?.counter?.indicator ?? "-",
                },
              ]}
            />
          )} */}

          {formDetails?.violation_type?.key === "hgt"
            ? formDetails?.counter_gas?.map((item, index) => (
                <>
                  <Typography.Text className="text-[#8498B4] font-normal text-[15px]">
                    {t("prilavok")} №{index + 1}
                  </Typography.Text>
                  <TabularData
                    key={item}
                    rows={[
                      {
                        name: t("gas_device_name"),
                        value: item?.gas_device_name ?? "-",
                      },
                      {
                        name: t("gas_device_type"),
                        value: item?.gas_device_type ?? "-",
                      },
                      {
                        name: t("gas_device_hourly_consumption"),
                        value: item?.gas_device_hourly_consumption ?? "-",
                      },
                      {
                        name: t("number_of_gas_devices"),
                        value: item?.number_of_gas_devices ?? "-",
                      },
                      {
                        name: t("counter_type"),
                        value: item?.counter_type ?? "-",
                      },
                      {
                        name: t("counter_serial_number"),
                        value: item?.counter_serial_number ?? "-",
                      },
                      {
                        name: t("counter_indicator"),
                        value: item?.counter_indicator ?? "-",
                      },
                      {
                        name: t("counter_indicator_date"),
                        value: formatDate(item?.counter_indicator_date, "DD.MM.YYYY") ?? "-",
                      },
                      {
                        name: t("stamp_numbers"),
                        value: item?.stamp_numbers ?? "-",
                      },
                      {
                        name: t("certificate_date_and_expiry"),
                        value: formatDate(item?.certificate_date_and_expiry, "DD.MM.YYYY") ?? "-",
                      },
                    ]}
                  />
                </>
              ))
            : formDetails?.counter_electric?.map((item, index) => (
                <>
                  <Typography.Text className="text-[#8498B4] font-normal text-[15px]">
                    {t("prilavok")} №{index + 1}
                  </Typography.Text>
                  <TabularData
                    key={item}
                    rows={[
                      {
                        name: t("counter_type"),
                        value: item?.counter_type ?? "-",
                      },
                      {
                        name: t("factory_number"),
                        value: item?.factory_number ?? "-",
                      },
                      {
                        name: t("electrical_transformer"),
                        value: item?.electrical_transformer ?? "-",
                      },
                      {
                        name: t("tension_transformer"),
                        value: item?.tension_transformer ?? "-",
                      },
                      {
                        name: t("active_indicator_plus"),
                        value: item?.active_indicator_plus ?? "-",
                      },
                      {
                        name: t("active_indicator_minus"),
                        value: item?.active_indicator_minus ?? "-",
                      },
                      {
                        name: t("no_active_indicator_plus"),
                        value: item?.no_active_indicator_plus ?? "-",
                      },
                      {
                        name: t("no_active_indicator_minus"),
                        value: item?.no_active_indicator_minus ?? "-",
                      },
                      {
                        name: t("total_power"),
                        value: item?.total_power ?? "-",
                      },
                    ]}
                  />
                </>
              ))}

          <Typography.Text className="text-[#8498B4] font-normal text-[15px]">
            {t("desc-nar")}
          </Typography.Text>
          <TabularData
            rows={[
              {
                name: t("statya"),
                value: `${formDetails?.law_article_part_obj?.name ?? ""} / ${
                  formDetails?.law_article_part_obj?.number ?? ""
                } / ${formDetails?.law_article_part_obj?.description ?? ""}`,
              },

              ...(formDetails?.additional_article?.map((item) => {
                return {
                  name: t("additional-articles"),
                  value: `${item?.name ?? ""} / ${item?.number ?? ""} / ${
                    item?.description ?? ""
                  }`,
                };
              }) ?? []),

              {
                name: t("prizank"),
                value: formDetails?.is_agree ? t("agree") : t("not-agree"),
              },
              {
                name: t("sign-nar"),
                value: formDetails?.violator_signature && (
                  <Button
                    type="link"
                    className="!underline font-normal text-sm p-0"
                    onClick={() => {
                      setOpenModalImage(formDetails?.violator_signature ?? "");
                    }}
                  >
                    {t("see")}
                  </Button>
                ),
              },
            ]}
          />

          {formDetails?.violation_type?.key === "hgt" && (
            <Flex>
              <div className="my-4 border w-full border-[#d7dde6] rounded-lg bg-[#fafbfc] leading-[56px]">
                <div className="flex items-center border-b border-b-[#d7dde6]">
                  <span className="px-4 w-[400px] min-w-[400px]">
                    {t("desc1")}
                  </span>
                  <Flex
                    style={{
                      gridTemplateColumns: `repeat(${formDetails?.according_connected_data?.length}, 1fr)`,
                    }}
                    className="grid w-full"
                  >
                    {formDetails?.according_connected_data?.map(
                      (item, index) => (
                        <Flex
                          key={item?.id}
                          vertical
                          gap={4}
                          className="px-4 border-l border-l-[#d7dde6]"
                        >
                          {item?.name ? (
                            <span className="text-sm">
                              {t("name")}: {item?.name}
                            </span>
                          ) : (
                            " "
                          )}

                          {item?.count ? (
                            <span className="text-sm">
                              {t("qty")}: {item?.count}
                            </span>
                          ) : (
                            " "
                          )}

                          {item?.capacity ? (
                            <span className="text-sm">
                              {t("power-kWt")}: {item?.capacity}
                            </span>
                          ) : (
                            " "
                          )}
                        </Flex>
                      ),
                    )}
                  </Flex>
                </div>

                <div className="flex items-center bg-white">
                  <span className="px-4 min-h-[56px] w-[400px] min-w-[400px]">
                    {t("Loyihadan tashqari gaz uskunalari")}
                  </span>
                  <Flex
                    style={{
                      gridTemplateColumns: `repeat(${formDetails?.according_connected_data?.length}, 1fr)`,
                    }}
                    className="grid grid-cols-5 w-full"
                  >
                    {formDetails?.according_connected_data?.map(
                      (item, index) => (
                        <span
                          key={item?.id}
                          className="px-4 min-h-[56px] border-l border-l-[#d7dde6]"
                        >
                          {item?.custom}
                        </span>
                      ),
                    )}
                  </Flex>
                </div>
              </div>
            </Flex>
          )}

          {/* <Typography.Text className="text-[#8498B4] font-normal text-[15px]">
            {t("inspector")}:
          </Typography.Text>

          <Flex
            vertical
            className="border border-1 border-solid border-[#8498B44D] rounded-[8px] p-[20px] bg-[#FAFBFC] my-4"
          >
            <Flex className="items-center justify-between">
              <Flex className="items-center" gap={20}>
                <Flex className="border border-1 border-solid border-[#8498B44D] overflow-hidden rounded-[8px] h-[48px] w-[48px] items-center justify-center bg-[#FFFFFF]">
                  <Image preview={false} src={DefaultAvatar} alt="phone" />
                </Flex>

                <Flex vertical gap={6}>
                  <Typography.Text className="text-[#081C15] font-medium text-base">
                    {formDetails?.employee?.firstname ?? ""}{" "}
                    {formDetails?.employee?.lastname ?? ""}{" "}
                    {formDetails?.employee?.middlename ?? ""}
                  </Typography.Text>
                  <Typography.Text className="text-[#3266D5] font-normal text-sm">
                    {t("inspector")}
                  </Typography.Text>
                </Flex>
              </Flex>

              <Flex className="items-center" gap={16}>
                <Button className="p-[8px_12px] flex gap-6 h-auto rounded-[8px] border border-1 border-solid border-[#D7DDE6]">
                  <Flex>
                    <Image src={passportIcon} alt="phone" preview />
                  </Flex>

                  <Flex vertical gap={4} className="items-start">
                    <Typography.Text className="text-[#8498B4] font-normal text-sm">
                      {t("pinfl")}:
                    </Typography.Text>
                    <Typography.Text className="text-[#081C15] font-normal text-sm">
                      {formDetails?.employee?.pinfl ?? "-"}
                    </Typography.Text>
                  </Flex>
                </Button>

                <Button className="p-[8px_12px] flex gap-6 h-auto rounded-[8px] border border-1 border-solid border-[#D7DDE6]">
                  <Flex>
                    <Image src={phoneIcon} alt="phone" preview />
                  </Flex>

                  <Flex vertical gap={4} className="items-start">
                    <Typography.Text className="text-[#8498B4] font-normal text-sm">
                      {t("telefon")}:
                    </Typography.Text>
                    <Typography.Text className="text-[#081C15] font-normal text-sm">
                      {formDetails?.employee?.phone_number ?? "-"}
                    </Typography.Text>
                  </Flex>
                </Button>

                <Button className="p-[8px_12px] flex gap-6 h-auto rounded-[8px] border border-1 border-solid border-[#D7DDE6]">
                  <Flex>
                    <Image src={signatureIcon} alt="phone" preview />
                  </Flex>

                  <Flex vertical gap={4} className="items-start">
                    <Typography.Text className="text-[#8498B4] font-normal text-sm">
                      {t("Imzo")}:
                    </Typography.Text>
                    <Button
                      type="link"
                      className="!underline font-normal text-sm p-0 h-auto"
                      onClick={() => {
                        setOpenModalImage(
                          formDetails?.employee?.signature ?? "",
                        );
                      }}
                    >
                      {t("see")}
                    </Button>
                  </Flex>
                </Button>
              </Flex>
            </Flex>
          </Flex> */}

          {formDetails?.employees?.length > 0 && (
            <>
              <Typography.Text className="text-[#8498B4] font-normal text-[15px]">
                {t("hodimlar")}:
              </Typography.Text>

              {formDetails?.employees?.map((item, index) => (
                <Flex
                  vertical
                  key={item?.id}
                  className="border border-1 border-solid border-[#8498B44D] rounded-[8px] p-[20px] bg-[#FAFBFC] my-4"
                >
                  <Flex className="items-center justify-between">
                    <Flex className="items-center" gap={20}>
                      <Flex className="border border-1 border-solid border-[#8498B44D] overflow-hidden rounded-[8px] h-[48px] w-[48px] items-center justify-center bg-[#FFFFFF]">
                        <Image
                          preview={false}
                          src={DefaultAvatar}
                          alt="phone"
                        />
                      </Flex>

                      <Flex vertical gap={6}>
                        <Typography.Text className="text-[#081C15] font-medium text-base">
                          {item?.firstname ?? ""} {item?.lastname ?? ""}{" "}
                          {item?.middlename ?? ""}
                        </Typography.Text>
                        <Typography.Text className="text-[#3266D5] font-normal text-sm">
                          {t("inspector")}
                        </Typography.Text>
                      </Flex>
                    </Flex>

                    <Flex className="items-center" gap={16}>
                      <Button className="p-[8px_12px] flex gap-6 h-auto rounded-[8px] border border-1 border-solid border-[#D7DDE6]">
                        <Flex>
                          <Image src={passportIcon} alt="phone" preview />
                        </Flex>

                        <Flex vertical gap={4} className="items-start">
                          <Typography.Text className="text-[#8498B4] font-normal text-sm">
                            {t("pinfl")}:
                          </Typography.Text>
                          <Typography.Text className="text-[#081C15] font-normal text-sm">
                            {item?.pinfl ?? "-"}
                          </Typography.Text>
                        </Flex>
                      </Button>

                      <Button className="p-[8px_12px] flex gap-6 h-auto rounded-[8px] border border-1 border-solid border-[#D7DDE6]">
                        <Flex>
                          <Image src={phoneIcon} alt="phone" preview />
                        </Flex>

                        <Flex vertical gap={4} className="items-start">
                          <Typography.Text className="text-[#8498B4] font-normal text-sm">
                            {t("telefon")}:
                          </Typography.Text>
                          <Typography.Text className="text-[#081C15] font-normal text-sm">
                            {item?.phone_number ?? "-"}
                          </Typography.Text>
                        </Flex>
                      </Button>

                      <Button className="p-[8px_12px] flex gap-6 h-auto rounded-[8px] border border-1 border-solid border-[#D7DDE6]">
                        <Flex>
                          <Image src={signatureIcon} alt="phone" preview />
                        </Flex>

                        {item?.signature && (
                          <Flex vertical gap={4} className="items-start">
                            <Typography.Text className="text-[#8498B4] font-normal text-sm">
                              {t("Imzo")}:
                            </Typography.Text>
                            <Button
                              type="link"
                              className="!underline font-normal text-sm p-0 h-auto"
                              onClick={() => {
                                setOpenModalImage(item?.signature ?? "");
                              }}
                            >
                              {t("see")}
                            </Button>
                          </Flex>
                        )}
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              ))}
            </>
          )}

          {formDetails?.participants?.length > 0 && (
            <>
              <Typography.Text className="text-[#8498B4] font-normal text-[15px]">
                {t("participant-organ")}:
              </Typography.Text>

              {formDetails?.participants?.map((item, index) => (
                <Flex
                  vertical
                  key={item?.id}
                  className="border border-1 border-solid border-[#8498B44D] rounded-[8px] p-[20px] bg-[#FAFBFC] my-4"
                >
                  <Flex className="items-center justify-between">
                    <Flex className="items-center" gap={20}>
                      <Flex className="border border-1 border-solid border-[#8498B44D] overflow-hidden rounded-[8px] h-[48px] w-[48px] items-center justify-center bg-[#FFFFFF]">
                        <Image
                          preview={false}
                          src={DefaultAvatar}
                          alt="phone"
                        />
                      </Flex>

                      <Flex vertical gap={6}>
                        <Typography.Text className="text-[#081C15] font-medium text-base">
                          {item?.participant_full_name ?? "-"}
                        </Typography.Text>
                      </Flex>
                    </Flex>

                    <Flex className="items-center" gap={16}>
                      <Button className="p-[8px_12px] flex gap-6 h-auto rounded-[8px] border border-1 border-solid border-[#D7DDE6]">
                        <Flex>
                          <Image src={phoneIcon} alt="phone" preview />
                        </Flex>

                        <Flex vertical gap={4} className="items-start">
                          <Typography.Text className="text-[#8498B4] font-normal text-sm">
                            {t("organization")}
                          </Typography.Text>
                          <Typography.Text className="text-[#081C15] font-normal text-sm">
                            {item?.participant_organization?.name ?? "-"}
                          </Typography.Text>
                        </Flex>
                      </Button>

                      <Button className="p-[8px_12px] flex gap-6 h-auto rounded-[8px] border border-1 border-solid border-[#D7DDE6]">
                        <Flex>
                          <Image src={signatureIcon} alt="phone" preview />
                        </Flex>

                        {item?.participant_signature && (
                          <Flex vertical gap={4} className="items-start">
                            <Typography.Text className="text-[#8498B4] font-normal text-sm">
                              {t("Imzo")}
                            </Typography.Text>
                            <Button
                              type="link"
                              className="!underline font-normal text-sm p-0 h-auto"
                              onClick={() => {
                                setOpenModalImage(
                                  item?.participant_signature ?? "",
                                );
                              }}
                            >
                              {t("see")}
                            </Button>
                          </Flex>
                        )}
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              ))}
            </>
          )}

          {formDetails?.witness?.length > 0 && (
            <>
              <Typography.Text className="text-[#8498B4] font-normal text-[15px]">
                {t("Guvoh")}:
              </Typography.Text>

              {formDetails?.witness?.map((item, index) => (
                <Flex
                  key={item?.id}
                  vertical
                  className="border border-1 border-solid border-[#8498B44D] rounded-[8px] p-[20px] bg-[#FAFBFC] my-4"
                >
                  <Flex className="items-center justify-between">
                    <Flex className="items-center" gap={20}>
                      <Flex className="border border-1 border-solid border-[#8498B44D] overflow-hidden rounded-[8px] h-[48px] w-[48px] items-center justify-center bg-[#FFFFFF]">
                        <Image
                          preview={false}
                          src={DefaultAvatar}
                          alt="phone"
                        />
                      </Flex>

                      <Flex vertical gap={6}>
                        <Typography.Text className="text-[#081C15] font-medium text-base">
                          {item?.firstname ?? ""} {item?.lastname ?? ""}{" "}
                          {item?.middlename ?? ""}
                        </Typography.Text>
                        <Typography.Text className="text-[#3266D5] font-normal text-sm">
                          {t("Guvoh")}
                        </Typography.Text>
                      </Flex>
                    </Flex>

                    <Flex className="items-center" gap={16}>
                      <Button className="p-[8px_12px] flex gap-6 h-auto rounded-[8px] border border-1 border-solid border-[#D7DDE6]">
                        <Flex>
                          <Image src={calendarIcon} alt="phone" preview />
                        </Flex>

                        <Flex vertical gap={4} className="items-start">
                          <Typography.Text className="text-[#8498B4] font-normal text-sm">
                            {t("dob")}
                          </Typography.Text>
                          <Typography.Text className="text-[#081C15] font-normal text-sm">
                            {item?.birth_date}
                          </Typography.Text>
                        </Flex>
                      </Button>

                      <Button className="p-[8px_12px] flex gap-6 h-auto rounded-[8px] border border-1 border-solid border-[#D7DDE6]">
                        <Flex>
                          <Image src={phoneIcon} alt="phone" preview />
                        </Flex>

                        <Flex vertical gap={4} className="items-start">
                          <Typography.Text className="text-[#8498B4] font-normal text-sm">
                            {t("telefon")}
                          </Typography.Text>
                          <Typography.Text className="text-[#081C15] font-normal text-sm">
                            {item?.phone}
                          </Typography.Text>
                        </Flex>
                      </Button>
                    </Flex>
                  </Flex>

                  <Divider className="my-[16px]" />

                  <Flex gap={36}>
                    <Flex vertical gap={8}>
                      <Typography.Text className="text-[#8498B4] font-normal text-sm">
                        {t("pinfl")}:
                      </Typography.Text>
                      <Typography.Text className="text-[#081C15] font-normal text-sm">
                        {item?.pinfl}
                      </Typography.Text>
                    </Flex>

                    <Flex vertical gap={8}>
                      <Typography.Text className="text-[#8498B4] font-normal text-sm">
                        {t("residence-address")}:
                      </Typography.Text>
                      <Typography.Text className="text-[#081C15] font-normal text-sm">
                        {item?.address}
                      </Typography.Text>
                    </Flex>

                    {item?.signature && (
                      <Flex vertical gap={8}>
                        <Typography.Text className="text-[#8498B4] font-normal text-sm">
                          {t("Imzo")}:
                        </Typography.Text>
                        <Button
                          type="link"
                          className="!underline font-normal text-sm p-0"
                          onClick={() => {
                            setOpenModalImage(item?.signature ?? "");
                          }}
                        >
                          {t("see")}
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              ))}
            </>
          )}

          <Image
            width={200}
            hidden
            src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
          />

          {formDetails?.status?.key === "under_consideration" && (
            <Flex className="w-full items-center justify-between">
              <Flex>
                <Button
                  onClick={() => {
                    setIsCancelModalOpen(true);
                  }}
                  className="border-[#EB5757] border border-1 border-solid text-[#EB5757] p-[15px_24px] h-auto"
                >
                  {t("nullify")}
                </Button>
              </Flex>

              <Flex gap={16}>
                <Button
                  onClick={() => {
                    setIsReturnModalOpen(true);
                  }}
                  className="border-[#8498B41A] bg-[#8498B41A] text-[#081C15] border border-1 border-solid p-[15px_24px] h-auto"
                >
                  {t("return-inspector")}
                </Button>
                <Button
                  onClick={onReceive}
                  // disabled
                  className="border-[#40916C] bg-[#40916C] border border-1 border-solid text-white p-[15px_24px] h-auto"
                >
                  {t("receive")}
                </Button>
              </Flex>
            </Flex>
          )}

          {/* <div className="flex items-center justify-between bg-[#fafbfc] border rounded-lg p-4 my-4">
            <div className="flex flex-col justify-center">
              <span className="text-[#081C15] font-medium text-base">
                {formDetails?.employee?.lastname}{" "}
                {formDetails?.employee?.firstname}{" "}
                {formDetails?.employee?.middlename}
              </span>
              <span className="text-[#40916C]">{t("inspector")}</span>
            </div>

            <span className="flex items-center gap-2">
              <img src={phoneIcon} alt="phone" />
              {typeof formDetails?.number !== "undefined"
                ? formatPhoneNumber(
                    formDetails?.created_by?.phone,
                    "+### ## ### ## ##",
                  )
                : null}
            </span>
          </div> */}
          {/* <TabularData
            rows={[
              {
                name: t("serial-number"),
                value: `${formDetails?.region?.serial ?? ""} ${
                  formDetails?.region?.code ?? ""
                }`.trim(),
              },
              {
                name: t("datetime"),
                value: formatDate(formDetails?.act_date ?? ""),
              },
              {
                name: t("address"),
                value: `${formDetails?.region?.name ?? ""}, ${
                  formDetails?.district?.name ?? ""
                }`.trim(),
              },
            ]}
          />

          <TabularData
            rows={[
              {
                name: t("Istemolchi FIO"),
                value:
                  `${formDetails?.employee?.firstname} ${formDetails?.employee?.lastname} ${formDetails?.employee?.middlename}`.trim(),
              },
              {
                name: t("Lavozim"),
                value: formDetails?.employee?.position,
              },
            ]}
          />

          <TabularData
            rows={[
              {
                name: t("Korxona nomi"),
                value: formDetails?.violation_organization?.name,
              },
              {
                name: t("Abonent raqami"),
                value: formDetails?.violation_organization?.contract_number,
              },
              {
                name: t("Joylashgan manzili"),
                value: formDetails?.violation_organization?.address,
              },
              {
                name: t("STIR"),
                value: formDetails?.violation_organization?.stir,
              },
              {
                name: t("Rahbari"),
                value: formDetails?.violation_organization?.director_fio,
              },
            ]}
          />

          <TabularData
            rows={[
              {
                name: t("Gaz hisoblagich rusumi"),
                value: formDetails?.counter?.counter_type,
              },
              {
                name: t("Zavod №"),
                value: formDetails?.counter?.factory_number,
              },
              {
                name: t("Ko'rsatkichi"),
                value: formDetails?.counter?.indicator,
              },
            ]}
          />

          <TabularData
            rows={[
              {
                name: t("Qoidabuzarlik bandlari"),
                value: [
                  formDetails?.law_article?.name
                    ? `${formDetails?.law_article?.name} - ${t("band")}`
                    : "",
                  formDetails?.law_article?.prim
                    ? `, ${formDetails?.law_article?.prim} - ${t(
                        "kichik band",
                      )}`
                    : "",
                  formDetails?.law_article?.number
                    ? `, ${formDetails?.law_article?.number} - ${t("qism")}`
                    : "",
                ]
                  .filter((s) => s !== "")
                  .join(" "),
              },
            ]}
          />

          <TabularData
            rows={[
              {
                name: t("Loyiha bo'yicha gaz uskunalari"),
                value: t("Loyihadan tashqari gaz uskunalari"),
              },
              { name: "", value: "" },
            ]}
          />

          <TabularData
            rows={[{ name: t("Fabula"), value: formDetails?.fabula }]}
          />

          <TabularData
            rows={[
              {
                name: t("Qoidabuzarlik sodir etgan shaxs"),
                value:
                  `${formDetails?.violation_person?.firstname} ${formDetails?.violation_person?.lastname} ${formDetails?.violation_person?.middlename}`.trim(),
              },
              {
                name: t("Imzo"),
                value: (
                  <Link
                    href={formDetails?.violation_person?.signature ?? ""}
                    baseless
                  />
                ),
              },
            ]}
          />

          <TabularData
            rows={[
              {
                name: t("participant-organ"),
                value: `${formDetails?.participant_organization?.name}`,
              },
              {
                name: t("participant-name"),
                value: (
                  <Link href={formDetails?.participant_full_name} baseless />
                ),
              },
              {
                name: t("Imzo"),
                value: (
                  // <Link href={formDetails?.participant_signature} baseless />
                  <Flex className="p-[12px]">
                    <Image
                      src={formDetails?.participant_signature}
                      width={70}
                      height={70}
                      preview={{
                        mask: <EyeOutlined />,
                      }}
                    />
                  </Flex>
                ),
              },
            ]}
          />

          <TabularData
            rows={[
              {
                name: t("Dalolatnoma tuzuvchi"),
                value:
                  `${formDetails?.employee?.firstname} ${formDetails?.employee?.lastname} ${formDetails?.employee?.middlename}`.trim(),
              },
              {
                name: t("Imzo"),
                value: (
                  // <Link href={formDetails?.employee?.signature} baseless />
                  <Flex className="p-[12px]">
                    <Image
                      src={formDetails?.employee?.signature}
                      width={70}
                      height={70}
                      // change text of preview icon in mask of image
                      preview={{
                        mask: <EyeOutlined />,
                      }}
                    />
                  </Flex>
                ),
              },
            ]}
          />

          {formDetails?.witness.length > 0 ? (
            formDetails?.witness?.map(
              ({ firstname, lastname, middlename, signature }) => (
                <TabularData
                  key={`witness-${firstname}-${lastname}-${middlename}`}
                  rows={[
                    {
                      name: t("Guvoh"),
                      value: `${firstname} ${lastname} ${middlename}`.trim(),
                    },
                    {
                      name: t("Imzo"),
                      value: (
                        // <Link href={signature} baseless />
                        <Flex className="p-[12px]">
                          <Image
                            src={signature}
                            width={70}
                            height={70}
                            preview={{
                              mask: <EyeOutlined />,
                            }}
                          />
                        </Flex>
                      ),
                    },
                  ]}
                />
              ),
            )
          ) : (
            <TabularData
              rows={[
                { name: t("Guvoh"), value: "" },
                {
                  name: t("Imzo"),
                  value: "",
                },
              ]}
            />
          )}

          {formDetails?.status?.key === "under_consideration" && (
            <div className="flex justify-between">
              <ConfigProvider
                theme={{
                  token: { colorPrimary: "#EB5757" },
                }}
              >
                <Button
                  className="text-[#EB5757] border-[#EB5757]"
                  size="large"
                  onClick={() => {
                    setIsCancelModalOpen(true);
                  }}
                >
                  {t("nullify")}
                </Button>
              </ConfigProvider>

              <div className="flex gap-4">
                <Button
                  type="text"
                  className="bg-[#f3f5f7]"
                  size="large"
                  onClick={() => {
                    setIsReturnModalOpen(true);
                  }}
                >
                  {t("return-inspector")}
                </Button>

                <Button
                  type="primary"
                  className="bg-[#40916C]"
                  size="large"
                  onClick={onReceive}
                >
                  {t("receive")}
                </Button>
              </div>
            </div>
          )} */}
        </Spin>
      </div>

      <CustomModal
        title={
          <div className="flex flex-col items-center gap-2">
            {nullified ? (
              <div className="bg-[#fafbfc] w-[96px] h-[96px] rounded-3xl flex items-center justify-center">
                <TickIcon style={{ color: "#40916C" }} />
              </div>
            ) : (
              <div className="bg-[#FAFBFC] w-[96px] h-[96px] rounded-3xl flex items-center justify-center">
                <CancelIcon style={{ color: "#EB5757" }} />
              </div>
            )}

            <Title level={4} className="text-center">
              {nullified ? t("success") : t("cancel-form")}
            </Title>
          </div>
        }
        open={isCancelModalOpen}
        footer={null}
        onCancel={onCancelModalCancel}
        centered
        width={400}
      >
        {nullified ? (
          <p className="text-center text-sm text-[#62738C]">
            {t("rejected-success")}
          </p>
        ) : (
          <p className="text-center text-sm text-[#62738C]">
            {t("sure-nullify")}
          </p>
        )}
        <br />
        <div className="flex justify-center gap-4">
          {nullified ? (
            <Button
              type="primary"
              size="large"
              className="bg-[#D8F3DC] text-[#40916C] w-[140px]"
              onClick={() => {
                setIsCancelModalOpen(false);
              }}
            >
              {t("close")}
            </Button>
          ) : (
            <>
              <Button
                type="text"
                size="large"
                className="bg-[#f3f5f7] w-[140px]"
                onClick={() => {
                  setIsCancelModalOpen(false);
                }}
              >
                {t("cancel")}
              </Button>

              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#EB5757",
                  },
                }}
              >
                <Button
                  type="primary"
                  size="large"
                  className="bg-[#EB5757] w-[140px]"
                  onClick={onNullify}
                >
                  {t("nullify")}
                </Button>
              </ConfigProvider>
            </>
          )}
        </div>
      </CustomModal>

      <CustomModal
        title={null}
        open={isOpenMapModal}
        footer={null}
        onCancel={onCloseMapModal}
        centered
        width={500}
      >
        <Flex className="w-full h-[500px]">
          <YMaps>
            <Map
              defaultState={{
                center: [
                  formDetails?.violation_organization?.latitude,
                  formDetails?.violation_organization?.longitude,
                ],
                zoom: 16,
              }}
              height="100%"
              width="100%"
            >
              <Placemark
                geometry={[
                  formDetails?.violation_organization?.latitude,
                  formDetails?.violation_organization?.longitude,
                ]}
              />
            </Map>
          </YMaps>
        </Flex>
        <br />
        <div className="flex justify-end gap-4">
          <Button
            type="primary"
            size="large"
            className="bg-[#D8F3DC] text-[#40916C] w-[140px]"
            onClick={() => {
              setIsOpenMapModal(false);
            }}
          >
            {t("close")}
          </Button>
        </div>
      </CustomModal>

      <CustomModal
        title={null}
        open={openModalImage !== null}
        footer={null}
        onCancel={() => {
          setOpenModalImage(null);
        }}
        centered
        width={500}
      >
        <Flex className="w-full h-[500px] min-h-[500px]">
          <Flex className="w-full h-full">
            <Image
              src={openModalImage}
              alt="map"
              preview={false}
              style={{
                objectFit: "contain",
                width: "100%",
                height: "100%",
                objectPosition: "center",
              }}
            />
          </Flex>
        </Flex>
        <br />
        <div className="flex justify-end gap-4">
          <Button
            type="primary"
            size="large"
            className="bg-[#D8F3DC] text-[#40916C] w-[140px]"
            onClick={() => {
              setOpenModalImage(null);
            }}
          >
            {t("close")}
          </Button>
        </div>
      </CustomModal>

      <CustomModal
        title={
          <Title level={4} className="text-center">
            {t("return-form")}
          </Title>
        }
        open={isReturnModalOpen}
        footer={null}
        onCancel={onReturnModalCancel}
        centered
        width={800}
      >
        <Info
          of={t("choose-return-reason")}
          className="flex flex-col gap-2"
          rootClassName="my-6"
        >
          <ConfigProvider
            theme={{
              components: {
                Select: {
                  colorBgContainer: "#fafbfc",
                },
              },
            }}
          >
            <Select
              placeholder={t("reason")}
              options={reasons}
              value={reason}
              onChange={(val) => {
                setReason(val);
              }}
              className="bg-[#fafbfc]"
            />
          </ConfigProvider>

          <Input.TextArea
            rows={4}
            placeholder={t("note") ?? ""}
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
            className="bg-[#fafbfc]"
          />
        </Info>

        <div className="flex justify-end gap-4">
          <Button
            type="text"
            size="large"
            className="bg-[#f3f5f7]"
            onClick={() => {
              setIsReturnModalOpen(false);
            }}
          >
            {t("cancel")}
          </Button>

          <Button
            type="text"
            size="large"
            className="bg-[#f3f5f7]"
            onClick={onReturn}
          >
            {t("return")}
          </Button>
        </div>
      </CustomModal>
    </>
  );
}
