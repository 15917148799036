import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const filterSvg: React.FC = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 13.5C8.00062 13.9668 6.6878 14.25 5.25 14.25C4.45056 14.25 3.68977 14.1625 3 14.0044C2.5566 13.9029 2.33489 13.8521 2.06923 13.6419C1.91772 13.522 1.72862 13.2847 1.64559 13.1102C1.5 12.8044 1.5 12.5077 1.5 11.9145V3.83548C1.5 3.09685 2.28002 2.58955 3 2.75445C3.68977 2.91244 4.45056 3 5.25 3C6.6878 3 8.00062 2.71677 9 2.25C9.99937 1.78323 11.3122 1.5 12.75 1.5C13.5494 1.5 14.3102 1.58756 15 1.74555C15.4434 1.84711 15.6651 1.89788 15.9307 2.10809C16.0822 2.22798 16.2714 2.46529 16.3544 2.63974C16.5 2.94563 16.5 3.24224 16.5 3.83548V11.9145C16.5 12.6532 15.72 13.1605 15 12.9956C14.3102 12.8375 13.5494 12.75 12.75 12.75C11.3122 12.75 9.99937 13.0332 9 13.5Z"
      stroke="currentColor"
      strokeWidth="1.2"
    />
    <path
      d="M1.5 15.75C2.4994 16.2168 3.8122 16.5 5.25 16.5C6.6878 16.5 8.00062 16.2168 9 15.75C9.99937 15.2832 11.3122 15 12.75 15C14.1878 15 15.5006 15.2832 16.5 15.75"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path
      d="M10.875 7.875C10.875 8.91052 10.0355 9.75 9 9.75C7.96447 9.75 7.125 8.91052 7.125 7.875C7.125 6.83947 7.96447 6 9 6C10.0355 6 10.875 6.83947 10.875 7.875Z"
      stroke="currentColor"
      strokeWidth="1.2"
    />
    <path
      d="M4.125 8.625V8.6325"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.875 7.11914V7.12662"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default function CashIcon(
  props: Partial<CustomIconComponentProps>,
): React.ReactElement {
  return <Icon component={filterSvg} {...props} />;
}
